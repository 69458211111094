
import template from './totp-setup.html';

/**
 * @constructor
 * @name TOTPSetupViewModel
 */
class TOTPSetupViewModel
{
	constructor (page) {
		this.page = page;


	};
	
}

/**
 * @constructor
 * @name TOTPSetupPage
 */
class TOTPSetupPage 
{
	constructor (bindings) {
		this.bindings = bindings;
		this.viewModel = new TOTPSetupViewModel(this);
		this.name = 'TOTPSetupPage';
	}
	
	//Event (Post/After): Load once
	init () {
		document.title = 'TOTP Setup';
	};

	async updateData () {
		try {
			let result = await fetch('/api/user/-1');
			let data = await result.json();
			
			let email = data.user.email || '';
			
			this.viewModel.email(email);
			if (email == '')
			{
				this.viewModel.enable_edit_email(true);
			}

			this.viewModel.otp_methods(data.user.auth_info.otp_methods);
			this.viewModel.mobile(data.user.mobile);
			this.viewModel.mobile_status(data.user.mobile_status);
			this.viewModel.roles_str(data.user.user_roles.join(', '));
		}catch (err) { 
			Grape.alert_api_error(err);
		};
	}

	//Event (Pre/Before): Remove 
	teardown = () => {
	}

}

export default {
	route: '[/]totp-setup',
	page_class: TOTPSetupPage,
	template: template
};
