
import components from './components/index.js';
import dialogs from './dialogs/index.js';
import pages from './pages/index.js';

import package_json from '../package.json';

//import './assets/styles/grape-ui-users.css';

import Plugin from './lib/Plugin.js';

(function() {

	window.dashboard_items = window.dashboard_items || [];

	window.Grape.modules.push(package_json);

	if (!window.UserAccountDialogs)
		window.UserAccountDialogs = [];
	window.UserAccountDialogs.push({
		title: 'Manage TOTP Settings',
		dialog_name: 'TOTPSetup',
		icon_class: 'fas fa-shield-alt'
	});


	components.forEach(function (component) {
		if (!component.module_type || component.module_type == 'ko')
		{
			ko.components.register(component.name, {
				template: component.template,
				viewModel: component.viewModel
			});
		}
	});

	dialogs.forEach(function (dialog) {
		window.Grape.dialog.dialogs[dialog.name] = {
			pageClass: dialog.dialog_class,
			template: dialog.template
		};

	});

	pages.forEach(function (page) {
		window.Grape.route(page.route, page);
	});

	Grape.plugins.register(Plugin);
})();


