
class TOTPPlugin
{
	constructor(Grape, options)
	{
		this.Grape = Grape;
		this.options = options;

		Grape.MFAPlugins_ui = Grape.MFAPlugins_ui || {};
		Grape.MFAPlugins_ui['totp'] = this;
	}

	get description () {
		return 'TOTP with app on mobile phone';
	}

	async onInit()
	{
	}

}

export default TOTPPlugin;

