import template from './totp-setup.html';

/**
 * @class
 * @name TOTPSetupDialogViewModel
 * @param {object} dialog - Page to which this ViewModel belongs
 */
class TOTPSetupViewModel {
	constructor (dialog) {
		this.dialog = dialog;
	}

	btnClose_click ()
	{
		this.dialog.close('');
	}

}

/**
 * @class
 * @name TOTPSetupDialog
 * @param {object} bindings - Bindings
 */
class TOTPSetupDialog {
	constructor (bindings)
	{
		this.viewModel = new TOTPSetupViewModel(this);
	}

	init ()
	{
	}

	async btnSave_click ()
	{
	}
}


export default {
	name: 'TOTPSetup',
	dialog_class: TOTPSetupDialog,
	template: template
};


