
import template from './totp-setup.html';

/**
 * @name TOTPSetupForm
 * @knockout
 *
 */
class viewModel
{
	constructor(params) {
		this.page = params.page || null;

		// Page status
		this.is_loading = ko.observable(true);
		this.is_status_ok = ko.observable(false);
		this.is_status_error = ko.observable(false);
		this.loading_message = ko.observable('');
		this.ok_message = ko.observable('');
		this.error_message = ko.observable('');
		this.status_code = ko.observable(0);
		this.status_log = ko.observableArray();

		// TOTP stuff
		this.otp = ko.observable('');
		this.otp_methods = ko.observableArray([]);
		this.totp_status = ko.observable('');
		this.totp_enrollment = ko.observable(false); // true while showing TOTP barcode
		this.show_enable_totp = ko.observable(false);
		this.qrcode_html = ko.observable('');

		// Init
		this.loadData();
	}

	setStatus (code, message)
	{
		this.status_code(code);
		this.status_log.push(message);

		this.is_loading(false);
		this.is_status_ok(false);
		this.is_status_error(false);

		if (code == 'READY')
		{
			this.is_status_ok(true);
			this.ok_message(message);
		}
		else if (code == 'BUSY')
		{
			this.is_loading(true);
			this.loading_message(message);
		}
		else
		{
			this.is_status_error(true);
			this.error_message(message);
		}
	}

	async loadData()
	{
		const response = await fetch('/api/user/-1');
		const result = await response.json();
		console.log('totp user=', result);

		this.totp_status(result.user.settings.totp_status || '');
		this.otp_methods(result.user.settings.otp_methods || []);

		if (this.totp_status() == '' || this.totp_status() == 'pending')
		{
			this.show_enable_totp(true);
			this.setStatus('WARN', 'TOTP is not currently enabled');
		}
		else if (this.totp_status() == 'ok')
		{
			this.setStatus('READY', 'TOTP is enabled.');
		}
		else
		{
			this.setStatus('ERROR', `Unknown status "${this.totp_status()}"`);
		}

	}

	otpInputKeydown(event)
	{
		console.log('otpInputKeydown', event);
		const ele = event.currentTarget;
		if (event.which == 8 || event.which == 37) //backspace or left arrow
		{
			if (ele.previousElementSibling)
			{
				ele.previousElementSibling.focus();
				ele.previousElementSibling.select();
			}
		}
		else if (event.which == 39) //right arrow
		{
			if (ele.nextElementSibling)
			{
				ele.nextElementSibling.focus();
				ele.nextElementSibling.select();
			}
		}
		else
		{
			let c = '';
			if (event.which >= 48 && event.which <= 57)
				c = event.which - 48;
			else
				return false;

			ele.value = c;

			let otp = '';
			let sibling = null;
			sibling = ele;
			while ((sibling = sibling.previousElementSibling))
				otp = [sibling.value, otp].join('');
			otp = [otp, ele.value].join('');
			sibling = ele;
			while ((sibling = sibling.nextElementSibling))
				otp = [otp, sibling.value].join('');

			console.log('OTP=',otp);
			this.otp(otp);

			if (ele.nextElementSibling)
			{
				ele.nextElementSibling.focus();
				ele.nextElementSibling.select();
			}
		}
		return false;
	}

	async btnEnableTOTP_click ()
	{
		this.show_enable_totp(false);
		this.setStatus('BUSY', 'Loading');
		const response = await fetch('/api/totp/enable', {method: 'POST'});
		const result = await response.json();
		if (result.status != 'OK')
		{
			this.setStatus('ERROR', result.message);
		}
		else
		{
			this.qrcode_html(result.qrcode);
			this.totp_enrollment(true);
			this.setStatus('READY', 'Ready');
		}
	}

	async btnConfirmTOTP_click ()
	{
		let otp = this.otp();

		if (!otp || !otp.match(/^[0-9]{6,6}$/))
		{
			Grape.alert({
				type: 'warning', 
				title: 'Complete OTP', 
				message: 'Please complete the OTP for initial verification'
			});
			return;
		}
		else
		{
			try {
				const response = await fetch('/api/totp/confirm', {
					method: 'POST',
					body: JSON.stringify({totp: otp}),
					headers: {'Content-type': 'application/json'}
				});
				const result = await response.json();

				if (response.ok)
				{
					Grape.alert({
						type: 'success',
						title: 'Successful',
						message: 'Your account now has two-factor authentication using time-based OTP enabled.'
					});
					this.totp_enrollment(false);
				}
				else
				{
					Grape.alert({
						type: 'danger',
						title: result.status,
						message: result.message
					});
				}
			} catch (err) {
				Grape.alert({
					type: 'danger',
					title: 'Network error',
					message: 'network error'
				});
			}
		}
	}

	async btnResetTOTP_click ()
	{
		let d = await Grape.prompt({
			type: 'warning', 
			title: 'Resetting OTP', 
			message: 'Resetting your OTP details will remove your old key. Are you sure you want to continue?',
			accept_text: 'Yes',
			cancel_text: 'No'
		});
		
		if (d)
		{
			this.setStatus('BUSY', 'Loading');
			await fetch('/api/totp/remove', {method: 'POST'});

			this.totp_status('');
			this.show_enable_totp(true);

			this.setStatus('READY', 'Ready');
		}
		return false;
	}
}

export default {
	name: 'ko-totp-setup',
	module_type: 'ko',
	template: template,
	viewModel: viewModel
};


